<template>
  <div class="mobile">
    <div class="head">
      <img
        src="../assets/parallel-ventures.svg"
        alt=""
        style="wisth: 256.91px; height: 28px;"
      />
    </div>
    <div class="introduce">
      Seeking Revolutionary Opportunities In A Digital New World
    </div>
    <img src="../assets/icon-arrow.svg" style="margin-left: 50px;" />
    <div class="about">
      <div class="about-head">About</div>
      <div class="content">
        <p>
          Parallel Ventures is founded in 2018, by several veterans from
          traditional first-tier venture capital firms. We are seeking
          opportunities to support the most brilliant and promising Web3 teams.
          Our investment focus is centered around the infrastructures,
          applications and innovative break-throughs of Web 3.
        </p>
        <p>
          We see ourselves as not only financiers but also co-builders of our
          investment portfolios. As a long term participant of the crypto
          industry, Parallel Ventures expect to work with more future leaders of
          the the Web 3 world.
        </p>
      </div>
    </div>
    <div class="portfolio">Portfolio</div>
    <div class="portfolio-box">
      <div class="portfolio-item">
        <img class="portfolio-item-cobo" src="../assets/cobo-default.png" />
      </div>
      <!-- <div class="portfolio-item">
        <img class="portfolio-item-babel" src="../assets/babel finance.png" />
      </div> -->
      <div class="portfolio-item">
        <img class="portfolio-item-keystone" src="../assets/keystone.png" />
      </div>
      <div class="portfolio-item">
        <img class="portfolio-item-conflux" src="../assets/conflux.png" />
      </div>
      <div class="portfolio-item">
        <img class="portfolio-item-nervos" src="../assets/nervos-logo.svg" />
      </div>
      <div class="portfolio-item">
        <img class="portfolio-item-mars" src="../assets/mars-logo.svg" />
      </div>
      <div class="portfolio-item">
        <img
          class="portfolio-item-impossible-finance"
          src="../assets/impossible-finance.svg"
        />
      </div>
      <div class="portfolio-item">
        <img class="portfolio-item-ONTology" src="../assets/ONTology.svg" />
      </div>
      <div class="portfolio-item">
        <img class="portfolio-item-YINLogo" src="../assets/YINLogo.svg" />
      </div>
      <div class="portfolio-item">
        <img class="portfolio-item-Bitmain" src="../assets/Bitmain.png" />
      </div>
      <div class="portfolio-item">
        <img class="portfolio-item-matrixport" src="../assets/matrixport.png" />
      </div>
      <div class="portfolio-item">
        <img
          class="portfolio-item-spartan-group"
          src="../assets/spartan-group.png"
        />
      </div>
    </div>
    <div class="contact">
      <div class="contact-head">Contact</div>
    </div>
    <div class="contact-box">
      <div class="contact-info">contact@parallelvc.xyz</div>
      <div>
        <img class="icon-Medium-white" src="../assets/icon-Medium-white.svg" />
        <img
          class="icon-twitter-white"
          src="../assets/icon-twitter-white.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  activited() {},
  update() {},
  methods: {},
  filter: {},
}
</script>

<style lang="scss" scoped>
@import "../assets/px2rem.scss";
.mobile {
  position: absolute;
  left: 0px;
  top: 0px;
  background-image: url("../assets/mobile-background.png");
  background-size: 100% 100%;
  width: px2rem(750);
  height: px2rem(1400);
  font-family: "FuturaBT-Medium";
  color: #ffffff;
  overflow: auto;
  .head {
    margin: px2rem(150) 0 px2rem(150) px2rem(100);
  }
  .introduce {
    display: flex;
    align-items: center;
    width: px2rem(556);
    margin-left: px2rem(100);
    margin-bottom: px2rem(150);
    font-size: px2rem(64);
    line-height: px2rem(96);
  }
  .about {
    margin-top: px2rem(180);
    margin-left: px2rem(100);
    .about-head {
      width: px2rem(210);
      height: px2rem(102);
      font-size: px2rem(64);
      line-height: px2rem(96);
    }
    .content {
      width: px2rem(556);
      font-style: normal;
      font-weight: 500;
      font-size: px2rem(28);
      line-height: px2rem(42);
      p {
        margin: 0;
        margin-top: px2rem(64);
      }
    }
  }
  .portfolio {
    height: px2rem(102);
    margin: px2rem(180) 0 px2rem(64) px2rem(100);
    font-size: px2rem(64);
    line-height: px2rem(96);
    display: flex;
    align-items: center;
    color: #ffffff;
  }
  .portfolio-box {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 px2rem(48);
    .portfolio-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: px2rem(200);
      height: px2rem(200);
      margin-right: px2rem(24);
      margin-bottom: px2rem(24);
      background: rgba(10, 18, 20, 0.1);
      box-shadow: 0px 0px px2rem(40) #4d5060;
      backdrop-filter: blur(16px);
      .portfolio-item-cobo {
        width: px2rem(129.06);
        height: px2rem(41.02);
      }
      .portfolio-item-babel {
        width: px2rem(139.92);
        height: px2rem(39.8);
      }
      .portfolio-item-keystone {
        width: px2rem(144.74);
        height: px2rem(33.78);
      }
      .portfolio-item-conflux {
        width: px2rem(150);
        height: px2rem(34.68);
      }
      .portfolio-item-nervos {
        width: px2rem(146);
        height: px2rem(25.3);
      }
      .portfolio-item-mars {
        width: px2rem(144);
        height: px2rem(46.7);
      }
      .portfolio-item-impossible-finance {
        width: px2rem(148);
        height: px2rem(38.24);
      }
      .portfolio-item-ONTology {
        width: px2rem(164);
        height: px2rem(30.66);
      }
      .portfolio-item-YINLogo {
        width: px2rem(130.32);
        height: px2rem(50.46);
      }
      .portfolio-item-Bitmain {
        width: px2rem(148);
        height: px2rem(19.82);
      }
      .portfolio-item-matrixport {
        width: px2rem(136);
        height: px2rem(73.24);
      }
      .portfolio-item-spartan-group {
        width: px2rem(100);
        height: px2rem(104.16);
      }
    }
    .portfolio-item:hover {
      cursor: pointer;
      box-shadow: 0px 0px px2rem(40) #f7931a;
    }
    .portfolio-item:nth-of-type(3n) {
      margin-right: 0;
    }
  }
  .contact {
    margin-top: px2rem(160);
    margin-left: px2rem(100);
    .contact-head {
      display: flex;
      align-items: center;
      font-size: px2rem(64);
      line-height: px2rem(96);
      width: px2rem(254);
      height: px2rem(102);
      margin-bottom: px2rem(24);
    }
  }
  .contact-box {
    display: flex;
    justify-content: space-between;
    margin: 0 px2rem(80) px2rem(100) px2rem(100);
    .contact-info {
      display: flex;
      align-items: center;
      // width: px2rem(310);
      // height: px2rem(36);
      font-style: normal;
      font-weight: 500;
      font-size: px2rem(28);
      line-height: px2rem(42);
    }
    .icon-Medium-white {
      width: px2rem(64);
      height: px2rem(64);
      margin-right: px2rem(24);
    }
    .icon-twitter-white {
      width: px2rem(64);
      height: px2rem(64);
    }
  }
}
</style>
