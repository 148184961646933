import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import mobile from "../views/mobile.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "Home",
    component: Home,
  },
  {
    path: "/Home",
    name: "Home",
    component: Home,
  },
  {
    path: "/mobile",
    name: "mobile",
    component: mobile,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// 全局钩子函数，跳转前执行
router.beforeEach((to, from, next) => {
  let isMobile = Vue.prototype.isMobile();
  if (to.fullPath == "/mobile" && isMobile) {
    return next();
  }
  if (to.fullPath == "/Home" && !isMobile) {
    return next();
  }
  if (isMobile) {
    return next("/mobile");
  } else {
    return next("/Home");
  }
});
export default router;
