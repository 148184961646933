<template>
  <div class="home">
    <div class="center">
      <img
        src="../assets/parallel-ventures.svg"
        alt=""
        style="wisth: 385.37px; height: 42px"
      />
    </div>
    <div class="content">
      <div class="tab line">
        <div class="tab-box">
          <div :class="['tab-num', index == 1 ? 'show-color' : 'hidden-color']">
            Ⅰ
          </div>
          <div :class="['point', index == 1 ? 'orange-point' : '']"></div>
          <div class="tab-item" @click="clickTab(1)">
            {{ index == 1 ? 'Home' : 'Ⅰ' }}
          </div>
        </div>
        <div class="tab-box">
          <div :class="['tab-num', index == 2 ? 'show-color' : 'hidden-color']">
            Ⅱ
          </div>
          <div :class="['point', index == 2 ? 'orange-point' : '']"></div>
          <div class="tab-item" @click="clickTab(2)">
            {{ index == 2 ? 'About' : 'Ⅱ' }}
          </div>
        </div>
        <div class="tab-box">
          <div :class="['tab-num', index == 3 ? 'show-color' : 'hidden-color']">
            Ⅲ
          </div>
          <div :class="['point', index == 3 ? 'orange-point' : '']"></div>
          <div class="tab-item" @click="clickTab(3)">
            {{ index == 3 ? 'Portfolio' : 'Ⅲ' }}
          </div>
        </div>
        <div class="tab-box">
          <div :class="['tab-num', index == 4 ? 'show-color' : 'hidden-color']">
            Ⅳ
          </div>
          <div :class="['point', index == 4 ? 'orange-point' : '']"></div>
          <div class="tab-item" @click="clickTab(4)">
            {{ index == 4 ? 'Contact' : 'Ⅳ' }}
          </div>
        </div>
      </div>
      <transition name="slide-fade" mode="out-in">
        <div v-if="index == 1" class="home-box" key="1">
          <div class="home-content">
            <p>Seeking Revolutionary</p>
            <p>Opportunities In</p>
            <p>A Digital New World</p>
          </div>
          <img src="../assets/icon-arrow.svg" />
        </div>
        <div v-if="index == 2" class="about-box" key="2">
          <div class="title">About</div>
          <div class="about-content">
            <p>
              Parallel Ventures is founded in 2018, by several veterans from
              traditional first-tier venture capital firms. We are seeking
              opportunities to support the most brilliant and promising Web3
              teams. Our investment focus is centered around the
              infrastructures, applications and innovative break-throughs of Web
              3.
            </p>
            <p>
              We see ourselves as not only financiers but also co-builders of
              our investment portfolios. As a long term participant of the
              crypto industry, Parallel Ventures expect to work with more future
              leaders of the the Web 3 world.
            </p>
          </div>
          <img src="../assets/icon-arrow.svg" />
        </div>
        <div v-if="index == 3" class="portfolio-box" key="3">
          <div class="portfolio-item">
            <img class="portfolio-item-cobo" src="../assets/cobo-default.png" />
          </div>
          <!-- <div class="portfolio-item">
            <img
              class="portfolio-item-babel"
              src="../assets/babel finance.png"
            />
          </div> -->
          <div class="portfolio-item">
            <img class="portfolio-item-keystone" src="../assets/keystone.png" />
          </div>
          <div class="portfolio-item">
            <img class="portfolio-item-conflux" src="../assets/conflux.png" />
          </div>
          <div class="portfolio-item">
            <img
              class="portfolio-item-nervos"
              src="../assets/nervos-logo.svg"
            />
          </div>
          <div class="portfolio-item">
            <img class="portfolio-item-mars" src="../assets/mars-logo.svg" />
          </div>
          <div class="portfolio-item">
            <img
              class="portfolio-item-impossible-finance"
              src="../assets/impossible-finance.svg"
            />
          </div>
          <div class="portfolio-item">
            <img class="portfolio-item-ONTology" src="../assets/ONTology.svg" />
          </div>
          <div class="portfolio-item">
            <img class="portfolio-item-YINLogo" src="../assets/YINLogo.svg" />
          </div>
          <div class="portfolio-item">
            <img class="portfolio-item-Bitmain" src="../assets/Bitmain.png" />
          </div>
          <div class="portfolio-item">
            <img
              class="portfolio-item-matrixport"
              src="../assets/matrixport.png"
            />
          </div>
          <div class="portfolio-item">
            <img
              class="portfolio-item-spartan-group"
              src="../assets/spartan-group.png"
            />
          </div>
        </div>
        <div v-if="index == 4" class="contact-box" key="4">
          <div class="title">Contact</div>
          <div class="contact-line"></div>
          <div class="email">E-mail:contact@parallelvc.xyz</div>
          <div class="contact-icon-box">
            <img
              class="icon-Medium-white"
              src="../assets/icon-Medium-white.svg"
            />
            <img
              class="icon-twitter-white"
              src="../assets/icon-twitter-white.svg"
            />
          </div>
          <img src="../assets/icon-arrow.svg" style="display: block" />
        </div>
      </transition>
    </div>
    <div class="bottom-line"></div>
    <div class="bottom-box">
      <div class="bottom-email">contact@parallelvc.xyz</div>
      <div class="bottom-icon">
        <img
          class="icon-Medium-white"
          :src="mediumOrange ? mediumOrangeUrl : mediumWhiteUrl"
          @mouseenter="enter('medium')"
          @mouseleave="leave('medium')"
        />
        <img
          class="icon-twitter-white"
          :src="twitterOrange ? twitterOrangeUrl : twitterWhiteUrl"
          @mouseenter="enter('twitter')"
          @mouseleave="leave('twitter')"
        />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

import { debounce } from '../utils/index';
export default {
  name: 'Home',
  components: {},
  data() {
    return {
      index: 1,
      mediumWhiteUrl: require('../assets/icon-Medium-white.svg'),
      mediumOrangeUrl: require('../assets/icon-Medium-orange.svg'),
      twitterWhiteUrl: require('../assets/icon-twitter-white.svg'),
      twitterOrangeUrl: require('../assets/icon-twitter-orange.svg'),
      mediumOrange: false,
      twitterOrange: false,
    };
  },
  mounted() {
    window.addEventListener('mousewheel', debounce(this.onMouseWheel), false);
    window.addEventListener(
      'DOMMouseScroll',
      debounce(this.onMouseWheel),
      false
    );
  },

  methods: {
    // 点击导航菜单，页面滚动到指定位置
    handleScroll(id) {
      if (id != 'home') {
        document
          .getElementById(`${id}`)
          .scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else {
        document.querySelector('html').scrollTop = 0;
      }
    },
    clickTab(index) {
      this.index = index;
    },
    enter(type) {
      if (type == 'medium') {
        this.mediumOrange = true;
      } else {
        this.twitterOrange = true;
      }
    },
    leave(type) {
      if (type == 'medium') {
        this.mediumOrange = false;
      } else {
        this.twitterOrange = false;
      }
    },
    onMouseWheel(e) {
      console.log(e);
      // this.debounce(this.changeIndex, 2000, true)(e.deltaY)
      if (e.deltaY > 0) {
        //向下滚动
        if (this.index != 4) {
          this.index++;
        } else {
          this.index = 1;
        }
      } else {
        if (this.index != 1) {
          this.index--;
        } else {
          this.index = 4;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  position: absolute;
  background-image: url('../assets/background.png');
  background-size: 100% 100%;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  color: #fff;
  font-family: 'FuturaBT-Medium';
  box-sizing: border-box;

  .center {
    margin: 72px 0 144px 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-weight: 800;
    font-size: 24px;
  }
  .content {
    display: flex;
    position: relative;
    overflow: 'scroll-y';
    .slide-fade-enter-active {
      transition: all 0.5s ease;
    }
    .slide-fade-leave-active {
      transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
      // transform: translateX(10px);
      opacity: 0;
    }
    .tab {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 147px;
      height: 388px;
      margin-left: 129px;
      font-size: 20px;
      color: #ffffff;
      .tab-box {
        display: flex;
        align-items: center;
        height: 31px;
        .tab-num {
          display: flex;
          align-items: center;
          text-align: right;
        }
        .show-color {
          color: #f7931a;
        }
        .hidden-color {
          color: #000004;
        }
        .point {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #4d5060;
          margin: 0 8px;
          z-index: 100;
        }
        .orange-point {
          background: #f7931a;
        }
        .tab-item {
          display: flex;
          align-items: center;
        }
        .tab-item:hover {
          cursor: pointer;
        }
      }
    }
    .line::before {
      content: ' ';
      height: 100%;
      position: absolute;
      width: 0px;
      height: 355px;
      border: 1px solid #4d5060;
      top: 17px;
      left: 28.5px;
    }
    .home-box {
      margin-left: 200px;
      .home-content {
        width: 545px;
        height: 202px;
        font-size: 42px;
        line-height: 63px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        margin-bottom: 120px;
        p {
          margin: 0;
        }
      }
      img {
        display: block;
      }
    }
    .about-box {
      margin-left: 150px;
      .title {
        width: 150px;
        height: 51px;
        font-size: 42px;
        line-height: 63px;
        display: flex;
        align-items: center;
        margin-bottom: 42px;
      }
      .about-content {
        width: 655px;
        // height: 170px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        p {
          margin: 0;
          margin-bottom: 30px;
        }
      }
      img {
        display: block;
        margin-top: 41px;
      }
    }
    .portfolio-box {
      display: flex;
      // justify-content: space-between;
      flex-wrap: wrap;
      position: absolute;
      top: -50px;
      // left: 292px;
      left: 413px;
      width: 750px;
      height: 565px;
      .portfolio-item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 150px;
        margin-right: 50px;
        margin-bottom: 40px;
        background: rgba(10, 18, 20, 0.1);
        box-shadow: 0px 0px 20px #4d5060;
        backdrop-filter: blur(16px);
        .portfolio-item-cobo {
          width: 107px;
          height: 34px;
        }
        .portfolio-item-babel {
          width: 116px;
          height: 33px;
        }
        .portfolio-item-keystone {
          width: 120px;
          height: 28px;
        }
        .portfolio-item-conflux {
          width: 126px;
          height: 29.13px;
        }
        .portfolio-item-nervos {
          width: 122px;
          height: 22.25px;
        }
        .portfolio-item-mars {
          width: 111px;
          height: 35px;
        }
        .portfolio-item-impossible-finance {
          width: 120px;
          height: 31px;
        }
        .portfolio-item-ONTology {
          width: 123px;
          height: 23px;
        }
        .portfolio-item-YINLogo {
          width: 98.84px;
          height: 38.27px;
        }
        .portfolio-item-Bitmain {
          width: 112px;
          height: 15.01px;
        }
        .portfolio-item-matrixport {
          width: 104px;
          height: 56px;
        }
        .portfolio-item-spartan-group {
          width: 72px;
          height: 75px;
        }
      }
      .portfolio-item:nth-child(4n) {
        margin-right: 0;
      }
      .portfolio-item:hover {
        cursor: pointer;
        box-shadow: 0px 0px 20px #f7931a;
      }
    }
    .contact-box {
      position: absolute;
      top: -50px;
      // margin-left: 150px;
      left: 424px;
      .title {
        width: 190px;
        height: 51px;
        font-size: 42px;
        line-height: 63px;
        display: flex;
        align-items: center;
      }
      .contact-line {
        width: 402px;
        height: 0px;
        border: 1px solid #ffffff;
        margin: 30px 0 34px 0;
      }
      .email {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 32px;
      }
      .contact-icon-box {
        display: flex;
        margin-bottom: 162px;
        .icon-Medium-white {
          width: 32px;
          height: 32px;
          margin-right: 24px;
        }
        .icon-twitter-white {
          width: 32px;
          height: 32px;
        }
        .icon-Medium-white:hover {
          cursor: pointer;
        }
        .icon-twitter-white:hover {
          cursor: pointer;
        }
      }
    }
  }
  .bottom-line {
    // position: absolute;
    // bottom: 102px;
    width: 82%;
    height: 0px;
    border: 1px solid #ffffff;
    margin: 300px auto 10px auto;
  }
  .bottom-box {
    // position: absolute;
    // bottom: 63px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 82%;
    margin: 0 auto;
    .bottom-email {
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #ffffff;
    }
    .bottom-icon {
      display: flex;
      .icon-Medium-white {
        width: 32px;
        height: 32px;
        margin-right: 24px;
      }
      .icon-twitter-white {
        width: 32px;
        height: 32px;
      }
      .icon-Medium-white:hover {
        cursor: pointer;
      }
      .icon-twitter-white:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
